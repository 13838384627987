<template>
  <div class="bedBox fatherHeight">
    <div class="bedList-serach flex-between">
      <div class="serach-left">
        <el-form
          :inline="true"
          :model="tableSearch"
          ref="searchForm"
          class="demo-form-inline w-144"
        >
          <el-select
            class="hospital"
            v-model="tableSearch.hospital_area_id"
            @change="hospitalChe"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hospitalList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="tableSearch.bed_area_id"
            clearable
            @change="getDataList"
            placeholder="请选择"
          >
            <el-option
              v-for="item in groupingList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form>
      </div>
      <div class="serach-right">
        <el-button class="color-main" @click="addBed" type="primary">
          <i class="fa fa-plus-circle"></i>新增
        </el-button>
      </div>
    </div>
    <div class="table" ref="tableBoxRef">
      <el-table
        v-loading="dataListLoading"
        :data="tableData"
        class="table-ellipsis"
        v-if="istable"
        size="small"
        border
        :max-height="tableHei"
      >
        <el-table-column prop="bed_no" label="床位号" width="80" />
        <el-table-column prop="hospital.name" label="所属院区" />
        <el-table-column prop="area.name" label="所属分组" />
        <el-table-column show-overflow-tooltip label="感染标记">
          <template #default="scope">
            <div v-if="scope.row.machine && scope.row.machine.infect_flag_name">
              <el-tag
                style="margin-right: 6px"
                size="small"
                v-for="(item, index) in scope.row.machine.infect_flag_name"
                :key="index"
                :type="item == '正常' ? '' : 'danger'"
              >
                {{ item }}
              </el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="machine_no" label="绑定透析机" />
        <el-table-column prop="sort" width="80" label="排序号" />
        <el-table-column prop="remark" label="备注" />
        <el-table-column fixed="right" label="操作" width="140">
          <template #default="scope">
            <el-button
              v-if="scope.row.machine_id > 0"
              type="text"
              size="small"
              @click="unbind(scope.row)"
            >
              <!-- <i class="txIons details"></i> -->
              <span class="txfonts binding">解</span>
            </el-button>
            <el-button
              v-else
              type="text"
              size="small"
              @click="binDing(scope.row)"
            >
              <!-- <i class="txIons details"></i> -->
              <span class="txfonts binding">绑</span>
            </el-button>
            <el-button type="text" size="small" @click="modParient(scope.row)">
              <i class="txIons modify"></i>
            </el-button>
            <el-button type="text" size="small" @click="delParient(scope.row)">
              <i class="txIons del"></i>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-box">
        <el-pagination
          :currentPage="pageData.currentPage"
          :page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <Add ref="addRef" @refreshDataList="getDataList"></Add>
    <BaseDialog
      title="绑定透析机"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="600px"
      top="20%"
    >
      <div class="tongluBox">
        <el-form
          ref="addSetupRef"
          :model="binDingData"
          label-width="110px"
          label-position="right"
          class="demo-ruleForm col-333"
        >
          <el-form-item
            label="透析机编号:"
            prop="machine_id"
            :rules="[
              {
                required: true,
                message: '请选择透析机',
                trigger: 'change',
              },
            ]"
          >
            <el-select
              class="hospital"
              v-model="binDingData.machine_id"
              @change="hospitalChe"
              placeholder="请选择"
            >
              <el-option
                v-for="item in machineList"
                :key="item.id"
                :label="item.machine_name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="accessItem"
            :loading="saveLoading"
            class="color-determine"
            type="primary"
          >
            保存
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, defineExpose, nextTick } from 'vue'
import service from '@/utils/request'
import Add from './add.vue'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const userInfo = localStorage.getItem('ms_userData')
const dataListLoading = ref(false)
const istable = ref(true)
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const tableSearch = reactive({
  spital_area_id: userInfo.hospital,
  bed_area_id: '',
})
const binDingData = reactive({
  id: '',
  machine_id: '',
})
const machineList = ref([])
const tableData = ref([])
const tableBoxRef = ref(null)
const addSetupRef = ref(null)
const tableHei = ref(0)

onMounted(async () => {
  istable.value = false
  tableHei.value = tableBoxRef.value.clientHeight - 40
  nextTick(() => {
    istable.value = true
  })
  await getHospitalList(0)
  initData()
})
const initData = () => {
  handleSearch()
}
defineExpose({
  initData,
})
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}
const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const getDataList = async () => {
  tableSearch.current = pageData.currentPage
  tableSearch.size = pageData.pageSize
  dataListLoading.value = true
  let res = await service.post('/api/bed/list', tableSearch)
  dataListLoading.value = false
  if (res.code === 0) {
    tableData.value = res.data.records
    pageData.totalSum = res.data.total
  }
}

const hospitalList = ref([])
const groupingList = ref([])
const getHospitalList = async (id) => {
  let res = await service.post('/api/hospital/list', {
    parent_id: id,
  })
  if (res.code === 0) {
    if (id == 0) {
      hospitalList.value = res.data
      if (!tableSearch.hospital_area_id) {
        tableSearch.hospital_area_id = res.data[0].id
        // tableSearch.hospital_area_name = res.data[0].name
        getHospitalList(res.data[0].id)
      }
    } else {
      groupingList.value = res.data
    }
  }
}
const hospitalChe = async (val) => {
  tableSearch.bed_area_id = ''
  getHospitalList(val)
}
const getMachineList = async (id) => {
  let res = await service.post('/api/machine/getBindMachine', {
    hospital_area_id: id,
  })
  if (res.code === 0) {
    machineList.value = res.data
  }
}
const visible = ref(false)
const saveLoading = ref(false)
const addRef = ref(null)
const accessItem = () => {
  addSetupRef.value.validate(async (valid) => {
    if (valid) {
      let res = await service.post('/api/bed/bind', binDingData)
      if (res.code === 0) {
        ElMessage.success(res.msg)
        visible.value = false
        getDataList()
      }
    }
  })
}
const addBed = () => {
  addRef.value.initData()
}
const modParient = (item) => {
  addRef.value.initData(item)
}
const binDing = (item) => {
  binDingData.id = item.id
  binDingData.machine_id = ''
  visible.value = true
  getMachineList(item.hospital_area_id)
}
const unbind = (item) => {
  ElMessageBox.confirm('真的要解绑么吗?', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/bed/unbind', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}
const delParient = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/bed/delete', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}
</script>

<style scoped lang="scss">
.bedList-serach {
  padding-top: 2px;
}
.table {
  height: calc(100% - 46px);
}
.hospital {
  margin-right: 10px;
}
.tongluBox {
  margin-top: 16px;
}
</style>
